<template>
  <div
    v-if="hasValidUser"
    class="plugins"
  >
    <Feature
      :name="FEATURE.ENDPOINT_MANAGER_PLUGIN"
      :options="{ user }"
    >
      <BrowzwearEndpointManager v-if="isBrowzwear" />
    </Feature>
    <div class="plugins__header">
      <div
        v-if="canChangeGroups"
        class="plugins__groups-control-wrapper"
      >
        <GroupsControl is-app-plugin />
      </div>
      <ElementTabs
        v-if="canChangeTabs"
        v-model="activeTab"
        class="plugins__navigation"
        data-testid="pluginNav"
      >
        <template v-for="tab in tabs">
          <ElementTabPane
            v-if="tab.show()"
            :key="tab.name"
            :label="tab.label"
            :name="tab.name"
          />
        </template>
      </ElementTabs>
    </div>
    <div
      v-if="!isErrorNotFound && canAccessResource"
      class="plugins__content"
    >
      <router-view />
    </div>
    <div
      v-else
      class="plugins__content plugins__content--message"
      data-testid="pluginContentMessage"
    >
      <h2>Nothing to see yet 🙈</h2>
      <br>
      <h3>Ask for an invite from a colleague!</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { BrowzwearPlugin } from '@/mixins/browzwearPlugin'
// platform, isBrowzwear
import { PluginUtils } from '@/mixins/pluginUtils'
import Utils from '@/services/utils'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { PLATFORM_TYPE } from '@/constants/platform'
import BrowzwearEndpointManager from '@/components/browzwear/BrowzwearEndpointManager'
import { FEATURE } from '@/constants/feature'
import GroupsControl from '@/components/layout/GroupsControl'

// canUserAccessTrimTabPlugin
import FeatureFlags from '@/services/featureFlags'

const ERROR_CODE_NOT_FOUND = 404

export default {
  name: 'Plugins',

  components: {
    GroupsControl,
    BrowzwearEndpointManager
  },

  mixins: [BrowzwearPlugin, PluginUtils],

  data () {
    return {
      errorCode: null,
      tabsByPlatform: {
        [PLATFORM_TYPE.BROWZWEAR]: [
          {
            label: 'Blocks',
            name: LIBRARY_TYPE.BLOCK,
            link: 'blocks',
            show: () => true
          },
          {
            label: 'Trims',
            name: LIBRARY_TYPE.TRIM,
            link: 'trims',
            show: FeatureFlags.canUserAccessTrimTabPlugin
          },
          {
            label: 'Fabrics',
            name: LIBRARY_TYPE.FABRIC,
            link: 'fabrics',
            show: () => true
          },
          {
            label: 'Stitches',
            name: LIBRARY_TYPE.STITCH,
            link: 'stitches',
            show: () => true
          }
        ]
      },
      activeTab: LIBRARY_TYPE.FABRIC,
      // Provides FEATURE.ENDPOINT_MANAGER_PLUGIN in the template.
      FEATURE
    }
  },

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData',
      isNotFound: 'getIsNotFound',
      activeTeam: 'getActiveTeam'
    }),

    /**
     * @returns {boolean}
     */
    canAccessResource () {
      return this.hasPermissions && this.isAuthorized
    },

    /**
     * @returns {boolean}
     */
    hasPermissions () {
      return this.user.permissions && this.user.permissions.length > 0
    },

    /**
     * @returns {string}
     */
    hasValidUser () {
      return this.user.email
    },

    /**
     * @returns {boolean}
     */
    isErrorNotFound () {
      return this.errorCode === ERROR_CODE_NOT_FOUND || this.isNotFound
    },

    /**
     * @returns {object[]}
     */
    tabs () {
      return this.tabsByPlatform[this.platform] || []
    },

    /**
     * @returns {boolean}
     */
    canChangeGroups () {
      return this.isBrowzwear
    },

    /**
     * @returns {boolean}
     */
    canChangeTabs () {
      return this.canAccessResource && this.tabs.length > 1
    }
  },

  watch: {
    /**
     * Only navigates to new route when selected tab changes
     *
     * @param {string} newTab
     */
    activeTab (newTab) {
      const tabToSearch = newTab.toLowerCase()
      const tabItem = this.tabs.find(tab => tab.name === tabToSearch)

      if (tabItem && this.$route.meta.libraryType !== tabItem.name) {
        this.$router.push(tabItem.link)
      }
    },

    /**
     */
    $route () {
      this.setErrorCode()
      this.setIsNotFound(false)
    }
  },

  async created () {
    if (this.platform === PLATFORM_TYPE.WEB) {
      this.$router.push('/')

      return
    }

    await this.$featureFlagsManager.initialize()
    await this.fetchCognitoUserData()

    this.setErrorCode()

    if (this.hasValidUser) {
      this.$tracking.identifyUser(this.user)
      Utils.trackLogin(this.user, this.platform)

      const currentRouteLibraryType = this.$route.meta.libraryType

      const defaultTab = this.tabs.find(
        tab => tab.name === currentRouteLibraryType
      )

      this.activeTab = defaultTab.name
    }
  },

  methods: {
    ...mapActions(['fetchCognitoUserData', 'setIsNotFound']),

    /**
     * This is set on the router/index and checks a callback method like FeatureFlags.canUserAccessStitchPlugin.
     * If false, shows a 404 to the user
     *
     */
    setErrorCode () {
      const check = this.$route.meta.check

      if (check && !check()) {
        this.errorCode = 404
      } else {
        this.errorCode = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$tabs-navigation-height: spacing(6);
$bw-content-height: calc(100% - #{$tabs-navigation-height});

.plugins {
  width: 100%;
  height: 100%;
}

.plugins__header {
  position: relative;
  z-index: $z-index-layer-0;
  display: flex;
}

.plugins__groups-control-wrapper {
  position: relative;
}

.plugins__content {
  width: 100%;
  height: $bw-content-height;
  padding-left: spacing(2);
}

.plugins__content--message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.plugins__navigation {
  @include flex-center;

  flex: 1 0 auto;
  height: $tabs-navigation-height;
  padding-top: spacing(1);
}
</style>
