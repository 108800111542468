<template>
  <div>
    <div class="browzwear-endpoint-manager__url-changer">
      <ElementButton
        size="mini"
        @click="toggleEndpointForm"
      >
        Change URL
      </ElementButton>
    </div>
    <StitchDialog
      :visible.sync="showEndpointChanger"
      :modal-fade="false"
      append-to-body
      center
      title="Manage Endpoint URL"
      :show-close="true"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      @closed="onClose"
    >
      <ElementInput
        v-model="newEndpoint"
        type="url"
        class="browzwear-endpoint-manager__url-changer--input"
        placeholder="http://your.url/browzwearPlugin/"
        clearable
        title="see a title"
        required
        @input="onInputChange"
      />

      <div slot="footer">
        <ElementButton
          type="primary"
          plain
          :disabled="!inputStringIsValidUrl"
          @click="onSubmit"
        >
          Change
        </ElementButton>
      </div>
    </StitchDialog>
    <span class="browzwear-endpoint-manager__current-url">{{
      currentEndpoint
    }}</span>
  </div>
</template>

<script>
// sendURLChangeToPlugin
import { BrowzwearPlugin } from '@/mixins/browzwearPlugin'
// isValidUrl
import { DataUtils } from '@/mixins/utils'

export default {
  name: 'BrowzwearEndpointManager',

  mixins: [DataUtils, BrowzwearPlugin],

  data: () => ({
    currentEndpoint: null,
    newEndpoint: '',
    showEndpointChanger: false,
    inputStringIsValidUrl: false
  }),

  computed: {
    /**
     * @returns {string}
     */
    normalizedCurrentEndpoint () {
      return this.currentEndpoint.replace(/\/+$/, '') + '/'
    }
  },

  watch: {
    /**
     * @param {string} newUrl
     * @param {string} oldUrl
     */
    currentEndpoint (newUrl, oldUrl) {
      if (oldUrl && newUrl !== oldUrl) {
        this.sendURLChangeToPlugin(this.normalizedCurrentEndpoint)
        window.location.replace(this.normalizedCurrentEndpoint)
      }
    }
  },

  created () {
    this.currentEndpoint = window.location.href
  },

  methods: {
    /**
     */
    toggleEndpointForm () {
      this.showEndpointChanger = !this.showEndpointChanger
    },

    /**
     */
    onClose () {
      this.newEndpoint = ''
      this.inputStringIsValidUrl = false
    },

    /**
     */
    onInputChange () {
      this.inputStringIsValidUrl = this.isValidUrl(this.newEndpoint)
    },

    /**
     */
    onSubmit () {
      if (this.newEndpoint && this.newEndpoint !== '') {
        const httpTestOnly = true

        if (this.isValidUrl(this.newEndpoint, httpTestOnly)) {
          this.currentEndpoint = this.newEndpoint
          this.showEndpointChanger = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$urlchanger-right-width: 12.6vw;
$urlchanger-right-min-width: 180px;
$currenturl-zindex: 1000;
$currenturl-bottom: 0.3em;
$currenturl-right: 1.7vw;
$currenturl-padding: 0.2em;
$currenturl-opacity: 0.6;

.browzwear-endpoint-manager__url-changer {
  position: fixed;
  right: 0;
  z-index: $currenturl-zindex;
  width: $urlchanger-right-width;
  min-width: $urlchanger-right-min-width;
  padding: spacing(1) spacing(2);
  text-align: center;
}

.browzwear-endpoint-manager__current-url {
  @include text-debug;

  position: fixed;
  right: $currenturl-right;
  bottom: $currenturl-bottom;
  z-index: $currenturl-zindex;
  padding: $currenturl-padding;
  background: $white;
  opacity: $currenturl-opacity;
}

/deep/ .browzwear-endpoint-manager__urlchanger--input {
  input:valid {
    border-color: $blue;
  }

  input:not(:placeholder-shown):invalid {
    border-color: $red;
  }
}
</style>
